import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Field } from "formik";
import React from "react";
import "tippy.js/dist/tippy.css";
import CommonTextField from "../../../../component/Input";
import ToogleButton from "../../../../component/ToogleButton";
import { HandleSkeletion } from "../../../../utils/constants";
import {
  CustomTooltip,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";
import { CustomizeTableCell, CustomTooltipField } from "../../style";

const SkillData = ({
  rowData,
  name,
  tableLoading,
  toggleRow,
  open,
  criticalityData,
  handleSkillToogleChange,
  tableContainerRef,
  headerData,
  handleDeleteSkillRow,
  handleSkillTextChange,
  alignment,
  isLoading,
}) => {
  return (
    <Field name={"skillData"}>
      {({ field, form }) => {
        const convertData =
          alignment === "Edit"
            ? form.values["skillData"][
                name === "primary"
                  ? "extracted_hard_skills"
                  : "extracted_soft_skills"
              ]
            : rowData;
        return (
          <TableContainerBorder
            scrollStyle={true}
            marginTop="1rem"
            height={6}
            ref={name === "primary" ? tableContainerRef : null}
          >
            <Table stickyHeader aria-label="sticky table" className="kathirvel">
              <TableHead>
                <TableRow>
                  {headerData.map((item, index) => {
                    return (
                      <React.Fragment key={item.name || index}>
                        {name === "primary" && index === 0 && (
                          <CustomizeTableCell key={index}>
                            {""}
                          </CustomizeTableCell>
                        )}
                        <CustomizeTableCell
                          key={index}
                          textAlign={index === 2 && "center"}
                          width={item.width}
                        >
                          {item.name}
                        </CustomizeTableCell>
                      </React.Fragment>
                    );
                  })}
                </TableRow>
              </TableHead>

              {isLoading || tableLoading ? (
                <>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <TableRow>
                      {Array.from({ length: 4 }).map((_, index) => (
                        <TableCell key={index}>
                          <HandleSkeletion height={30} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableBody>
                  {convertData?.map((row, index) => {
                    return (
                      <>
                        {name === "primary" && (
                          <React.Fragment key={index}>
                            <TableRow
                              className={`primary_${index}`}
                              key={index}
                            >
                              <TableCell>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => toggleRow(index)}
                                >
                                  {open[index] ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>

                              <CustomizeTableCell
                                fontWeight={800}
                                sx={{ width: "40%" }}
                              >
                                {row?.classification}
                              </CustomizeTableCell>
                              <TableCell></TableCell>
                              <CustomizeTableCell
                                width="20%"
                                fontWeight={800}
                                textAlign={"center"}
                                verticalAlign={"middle"}
                              ></CustomizeTableCell>
                              {alignment === "Edit" && (
                                <CustomizeTableCell
                                  verticalAlign={"middle"}
                                  width={"5%"}
                                >
                                  <CustomTooltip title="Delete" color="#000">
                                    <DeleteIcon
                                      style={{
                                        cursor: "pointer",
                                        width: "22px",
                                      }}
                                      onClick={() =>
                                        handleDeleteSkillRow(
                                          row,
                                          null,
                                          form,
                                          "primary"
                                        )
                                      }
                                    />
                                  </CustomTooltip>
                                </CustomizeTableCell>
                              )}
                            </TableRow>
                            {open[index] &&
                              row?.skill?.map((elt, idx) => (
                                <TableRow key={index + idx}>
                                  <TableCell
                                    colSpan={headerData.length + 1}
                                    style={{ padding: 0, margin: 0 }}
                                  >
                                    <Collapse
                                      in={open[index]}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <CustomizeTableCell
                                        fontWeight={500}
                                        sx={{
                                          width:
                                            alignment === "Edit"
                                              ? "40%"
                                              : "45%",
                                        }}
                                      >
                                        {alignment === "Edit" ? (
                                          <div style={{ position: "relative" }}>
                                            <CommonTextField
                                              padding="10px 0px 10px 10px"
                                              width={"100%"}
                                              label={"Skill"}
                                              labelShrink={"10px"}
                                              value={elt?.skill}
                                              fontSize={"14px"}
                                              onChange={(event) =>
                                                handleSkillTextChange(
                                                  event,
                                                  index,
                                                  idx,
                                                  form,
                                                  "primary",
                                                  "skill"
                                                )
                                              }
                                              error={
                                                form?.errors?.skillData?.[
                                                  "extracted_hard_skills"
                                                ]?.[index]?.["skill"]?.[idx]?.[
                                                  "skill"
                                                ] &&
                                                form?.touched?.skillData?.[
                                                  "extracted_hard_skills"
                                                ]?.[index]?.["skill"]?.[idx]?.[
                                                  "skill"
                                                ]
                                              }
                                            />
                                            {form?.errors?.skillData?.[
                                              "extracted_hard_skills"
                                            ]?.[index]?.["skill"]?.[idx]?.[
                                              "skill"
                                            ] &&
                                              form?.touched?.skillData?.[
                                                "extracted_hard_skills"
                                              ]?.[index]?.["skill"]?.[idx]?.[
                                                "skill"
                                              ] && (
                                                <CustomTooltipField>
                                                  {
                                                    form?.errors?.skillData?.[
                                                      "extracted_hard_skills"
                                                    ]?.[index]?.["skill"]?.[
                                                      idx
                                                    ]?.["skill"]
                                                  }
                                                </CustomTooltipField>
                                              )}
                                          </div>
                                        ) : (
                                          elt?.skill
                                        )}
                                      </CustomizeTableCell>
                                      <CustomizeTableCell
                                        verticalAlign={"middle"}
                                        width={"40%"}
                                      >
                                        <ToogleButton
                                          alignment={elt?.category}
                                          padding={"5px 10px"}
                                          ToogleData={criticalityData}
                                          handleChange={
                                            alignment === "Edit"
                                              ? (event, newAlignment) =>
                                                  handleSkillToogleChange(
                                                    event,
                                                    newAlignment,
                                                    index,
                                                    idx,
                                                    form,
                                                    "primary"
                                                  )
                                              : () => {}
                                          }
                                          gap={"10px"}
                                          fontSize={"12px"}
                                          border={"none !important"}
                                          borderRadius={"5px !important"}
                                          activeColor={"#FFFF"}
                                          color={"#667085"}
                                          activeBackgroundColor={"#7F56D9"}
                                          backgroundColor={"#EAECF0 !important"}
                                        />
                                      </CustomizeTableCell>
                                      <CustomizeTableCell
                                        fontWeight={500}
                                        textAlign={"center"}
                                        verticalAlign={"middle"}
                                        width={"15%"}
                                      >
                                        {alignment === "Edit" ? (
                                          <div style={{ position: "relative" }}>
                                            <CommonTextField
                                              padding="7px 0px 10px 10px"
                                              fontSize={"15px"}
                                              width={"20px"}
                                              label={"Year"}
                                              labelShrink={"10px"}
                                              value={elt?.experience_in_yrs}
                                              onChange={(event) =>
                                                handleSkillTextChange(
                                                  event,
                                                  index,
                                                  idx,
                                                  form,
                                                  "primary",
                                                  "experience_in_yrs"
                                                )
                                              }
                                              error={
                                                form?.errors?.skillData?.[
                                                  "extracted_hard_skills"
                                                ]?.[index]?.["skill"]?.[idx]?.[
                                                  "experience_in_yrs"
                                                ] &&
                                                form?.touched?.skillData?.[
                                                  "extracted_hard_skills"
                                                ]?.[index]?.["skill"]?.[idx]?.[
                                                  "experience_in_yrs"
                                                ]
                                              }
                                            />
                                            {form?.errors?.skillData?.[
                                              "extracted_hard_skills"
                                            ]?.[index]?.["skill"]?.[idx]?.[
                                              "experience_in_yrs"
                                            ] &&
                                              form?.touched?.skillData?.[
                                                "extracted_hard_skills"
                                              ]?.[index]?.["skill"]?.[idx]?.[
                                                "experience_in_yrs"
                                              ] && (
                                                <CustomTooltipField>
                                                  {
                                                    form?.errors?.skillData?.[
                                                      "extracted_hard_skills"
                                                    ]?.[index]?.["skill"]?.[
                                                      idx
                                                    ]?.["experience_in_yrs"]
                                                  }
                                                </CustomTooltipField>
                                              )}
                                          </div>
                                        ) : (
                                          elt.experience_in_yrs
                                        )}
                                      </CustomizeTableCell>
                                      {alignment === "Edit" ? (
                                        <CustomizeTableCell
                                          verticalAlign={"middle"}
                                          width={"5%"}
                                        >
                                          <CustomTooltip
                                            title="Delete"
                                            color="#000"
                                          >
                                            <DeleteIcon
                                              style={{
                                                cursor: "pointer",
                                                width: "22px",
                                              }}
                                              onClick={() =>
                                                handleDeleteSkillRow(
                                                  elt,
                                                  row,
                                                  form,
                                                  "primary"
                                                )
                                              }
                                            />
                                          </CustomTooltip>
                                        </CustomizeTableCell>
                                      ) : (
                                        <TableCell
                                          style={{ padding: "5px" }}
                                        ></TableCell>
                                      )}
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </React.Fragment>
                        )}
                        {name === "secondary" && (
                          <TableRow key={index}>
                            <CustomizeTableCell
                              fontWeight={500}
                              sx={{ width: "40%" }}
                            >
                              {alignment === "Edit" ? (
                                <div style={{ position: "relative" }}>
                                  <CommonTextField
                                    padding="10px 0px 10px 10px"
                                    width={"100%"}
                                    label={"Skill"}
                                    labelShrink={"10px"}
                                    value={row?.skill}
                                    fontSize={"14px"}
                                    onChange={(event) =>
                                      handleSkillTextChange(
                                        event,
                                        index,
                                        null,
                                        form,
                                        "secondary",
                                        "skill"
                                      )
                                    }
                                    error={
                                      form?.errors?.skillData?.[
                                        "extracted_soft_skills"
                                      ]?.[index]?.["skill"] &&
                                      form?.touched?.skillData?.[
                                        "extracted_soft_skills"
                                      ]?.[index]?.["skill"]
                                    }
                                  />
                                  {form?.errors?.skillData?.[
                                    "extracted_soft_skills"
                                  ]?.[index]?.["skill"] &&
                                    form?.touched?.skillData?.[
                                      "extracted_soft_skills"
                                    ]?.[index]?.["skill"] && (
                                      <CustomTooltipField>
                                        {
                                          form?.errors?.skillData?.[
                                            "extracted_soft_skills"
                                          ]?.[index]?.["skill"]
                                        }
                                      </CustomTooltipField>
                                    )}
                                </div>
                              ) : (
                                row?.skill
                              )}
                            </CustomizeTableCell>
                            <TableCell sx={{ width: "40%" }}>
                              <ToogleButton
                                alignment={row?.category}
                                padding={"5px 10px"}
                                ToogleData={criticalityData}
                                handleChange={
                                  alignment === "Edit"
                                    ? (event, newAlignment) =>
                                        handleSkillToogleChange(
                                          event,
                                          newAlignment,
                                          index,
                                          null,
                                          form,
                                          "secondary"
                                        )
                                    : () => {}
                                }
                                gap={"10px"}
                                fontSize={"12px"}
                                border={"none !important"}
                                borderRadius={"5px !important"}
                                activeColor={"#FFFF"}
                                color={"#667085"}
                                activeBackgroundColor={"#7F56D9"}
                                backgroundColor={"#EAECF0 !important"}
                              />
                            </TableCell>

                            <CustomizeTableCell
                              fontWeight={500}
                              textAlign={"center"}
                              verticalAlign={"middle"}
                              width={"15%"}
                            >
                              {alignment === "Edit" ? (
                                <div style={{ position: "relative" }}>
                                  <CommonTextField
                                    padding="7px 0px 10px 10px"
                                    fontSize={"15px"}
                                    width={"20px"}
                                    label={"Year"}
                                    labelShrink={"10px"}
                                    value={row?.experience_in_yrs}
                                    onChange={(event) =>
                                      handleSkillTextChange(
                                        event,
                                        index,
                                        null,
                                        form,
                                        "secondary",
                                        "experience_in_yrs"
                                      )
                                    }
                                    error={
                                      form?.errors?.skillData
                                        ?.extracted_soft_skills?.[index]
                                        ?.experience_in_yrs &&
                                      form?.touched?.skillData
                                        ?.extracted_soft_skills?.[index]
                                        ?.experience_in_yrs
                                    }
                                  />
                                  {form?.errors?.skillData
                                    ?.extracted_soft_skills?.[index]
                                    ?.experience_in_yrs &&
                                    form?.touched?.skillData
                                      ?.extracted_soft_skills?.[index]
                                      ?.experience_in_yrs && (
                                      <CustomTooltipField>
                                        {
                                          form?.errors?.skillData
                                            ?.extracted_soft_skills?.[index]
                                            ?.experience_in_yrs
                                        }
                                      </CustomTooltipField>
                                    )}
                                </div>
                              ) : (
                                row.experience_in_yrs
                              )}
                            </CustomizeTableCell>
                            {alignment === "Edit" && (
                              <CustomizeTableCell
                                verticalAlign={"middle"}
                                width={"5%"}
                              >
                                <CustomTooltip title="Delete" color="#000">
                                  <DeleteIcon
                                    style={{
                                      cursor: "pointer",
                                      width: "22px",
                                    }}
                                    onClick={() =>
                                      handleDeleteSkillRow(
                                        row,
                                        null,
                                        form,
                                        "secondary"
                                      )
                                    }
                                  />
                                </CustomTooltip>
                              </CustomizeTableCell>
                            )}
                          </TableRow>
                        )}
                      </>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainerBorder>
        );
      }}
    </Field>
  );
};

export default SkillData;
