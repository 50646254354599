import { Box, Grid2, TextField, Typography } from "@mui/material";
import React from "react";
const interviewStages = [
  { label: "First Touch", value: "Interview with Hiring Manager" },
  { label: "Second Touch", value: "Panel Video Interview" },
  { label: "Third Touch", value: "N/A" },
  { label: "Fourth Touch", value: "N/A" },
];
function InterviewSettings() {
  return (
    <Grid2 container spacing={3}>
      <Grid2 item size={12}>
        <Typography
          sx={{ fontSize: "16px", fontWeight: 500, color: "#101828" }}
        >
          Interview Settings
        </Typography>
      </Grid2>
      {interviewStages.map((stage, index) => (
        <Grid2 item size={6} key={index}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {stage.label}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            defaultValue={stage.value}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #D0D5DD",
              },
              "& .MuiInputBase-input": {
                padding: "12px",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#667085",
                opacity: 1,
                fontSize: "16px",
                fontWeight: 400,
              },
            }}
          />
        </Grid2>
      ))}
    </Grid2>
  );
}

export default InterviewSettings;
