import { Box, Dialog, List, Paper, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

// export const StyledFlexRowView = styled("div")(() => ({
//   display: "flex",
//   flexDirection: "row",
//   alignItems: "center",
//   gap: "10px",
// }));

// export const StyledFlexColumnView = styled("div")(() => ({
//   display: "flex",
//   flexDirection: "column",
//   //   alignItems: "center",
//   gap: "10px",
// }));

// export const StyledCheckBox = styled(Checkbox)(() => ({
//   padding: "0px !important",
//   color: "#DF1278",
//   "&.Mui-checked": {
//     color: "#DF1278",
//   },
// }));

export const StyledTabs = styled(Tabs)(() => ({
  "& .MuiTabs-flexContainer": {
    justifyContent: "space-between", // Spread tabs across full width
    width: "100%",
  },
  "& .MuiTabs-indicator": {
    display: "none", // Remove the default bottom indicator
  },
}));

export const StyledTab = styled(Tab)(({ value, selectedValue }) => ({
  flexGrow: 1,
  color: value === selectedValue ? "#7F56D9" : "#344054",
  fontWeight: value === selectedValue ? "bold" : "normal",
  textTransform: "none",
  fontSize: "1rem",
  borderTop:
    value === selectedValue ? "4px solid #6941C6" : "4px solid #EAECF0",
  marginX: 1, // Add horizontal margin to create gap between tabs
  "&:hover": {
    color: "#6941C6",
    fontWeight: "bold",
    borderTop: "4px solid #6941C6",
  },
  minWidth: "auto", // Removes min-width constraint
  maxWidth: "none",
  px: 2, // Adjust padding for the tab
  paddingLeft: "0px",
}));

export const FileContainer = styled(Paper)(({ gap }) => ({
  display: "flex",
  alignItems: "flex-start",
  padding: "16px",
  borderRadius: "12px",
  border: "1px solid #EAECF0",
  marginBottom: "16px",
  background: "#FFF",
  boxShadow: "none",
  gap: gap,
}));

export const StyledSpaceBetweenBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  justifyContent: "space-between",
}));

export const StyledLinearContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  mt: 1,
}));

export const UploadDiv = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin: 0 auto;
`;

export const FileSpan = styled("span")(({ fontWeight, color }) => ({
  color: color,
  fontWeight: fontWeight,
}));

export const CustomList = styled(List)(({ fontWeight, color }) => ({
  color: color,
  fontWeight: fontWeight,
  "& .MuiListItem-root ": {
    wordBreak: "break-word",
  },
}));

export const CommonDialogStyle = styled(Dialog)(({}) => ({
  "& .MuiDialog-paper": {
    borderRadius: "12px",
    background: "#FFF",
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  },
  "& .MuiAutocomplete-root": {
    "& .MuiChip-deletable": {
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      backgroundColor: "#FFFFFF",
      border: "1px solid #D0D5DD",

      "& .MuiOutlinedInput-input": {
        padding: "0px",
      },
    },
    "& .MuiFormHelperText-root": {
      margin: "3px 0px",
      fontWeight: 600,
    },
  },
  "& .MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-filledDefault":
    {
      marginRight: 8,
      fontSize: "14px",
      fontWeight: 600,
      color: "#344054",
      backgroundColor: "white",
      gap: "12px !important",
      display: "flex",
      alignItems: "center",
      border: "1px solid #FFF",
      padding: "0px !important",
    },
}));
