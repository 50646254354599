import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid2,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import DotsGrid from "../../../../assets/icons/DotsGrid";
import DeleteIco from "../../../../assets/icons/DeleteIco";
import { StyledFlexRowView } from "../../../common-components/StyledComponents";

const questions = [
  { id: 1, text: "How are you?" },
  { id: 2, text: "Do you like sushi?" },
];
function SeededPreScreen() {
  return (
    <Grid2 container spacing={3}>
      <Grid2 item size={12}>
        <Typography
          sx={{ fontSize: "16px", fontWeight: 500, color: "#101828" }}
        >
          {"Seeded Pre-Screen Questions"}
        </Typography>
      </Grid2>

      {questions.map((question) => (
        <Grid2 item size={12}>
          <StyledFlexRowView>
            <Paper
              variant="outlined"
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "12px",
                boxShadow: "box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                borderRadius: "12px",
                border: "1px solid #EAECF0 !important",
              }}
            >
              <Typography
                sx={{ fontSize: "16px", fontWeight: 500, color: "#667085" }}
              >
                {question?.text}
              </Typography>
            </Paper>

            <IconButton
              size="small"
              sx={{
                border: "1px solid #D0D5DD",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              <DotsGrid />
            </IconButton>
            <IconButton
              size="small"
              sx={{
                border: "1px solid #D0D5DD",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              <DeleteIco />
            </IconButton>
          </StyledFlexRowView>
        </Grid2>
      ))}

      <Grid2 item size={12}>
        <Button
          variant="outlined"
          size="small"
          sx={{
            padding: "10px 14px",
            borderRadius: "8px",
            // marginLeft: "15px",
            color: "#344054",
            border: "1px solid #D0D5DD",
            "&:hover": {
              backgroundColor: "#7F56D9",
            },
            fontWeight: "bold",
          }}
        >
          {"Add Questions"}
        </Button>
      </Grid2>

      <Grid2 item size={12}>
        <FormControlLabel
          control={<Checkbox defaultChecked />}
          label="Enable Dynamic Interview Module"
          sx={{ fontSize: "14px", fontWeight: 500, color: "#344054" }}
        />
      </Grid2>

      <Grid2 item size={12}>
        <StyledFlexRowView>
          <Button
            variant="outlined"
            size="small"
            sx={{
              padding: "10px 14px",
              borderRadius: "8px",
              // marginLeft: "15px",
              color: "#344054",
              border: "1px solid #D0D5DD",
              "&:hover": {
                backgroundColor: "#7F56D9",
              },
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {"Submit"}
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              padding: "10px 14px",
              borderRadius: "8px",
              // marginLeft: "15px",
              color: "#344054",
              border: "1px solid #D0D5DD",
              "&:hover": {
                backgroundColor: "#7F56D9",
              },
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {"Share"}
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              padding: "10px 14px",
              borderRadius: "8px",
              // marginLeft: "15px",
              color: "#344054",
              border: "1px solid #D0D5DD",
              "&:hover": {
                backgroundColor: "#7F56D9",
              },
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {"Generate Again"}
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              padding: "10px 14px",
              borderRadius: "8px",
              // marginLeft: "15px",
              color: "#B42318",
              border: "1px solid #B42318",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#B42318",
                color: "white",
              },
              fontWeight: "bold",
            }}
          >
            {"Delete"}
          </Button>
        </StyledFlexRowView>
      </Grid2>
    </Grid2>
  );
}

export default SeededPreScreen;
