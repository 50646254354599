import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ChevronDownIco from "../../assets/icons/ChevronDownIco";
import RecordingIco from "../../assets/icons/RecordingIco";
import XCloseIco from "../../assets/icons/XCloseIco";
import NoteBackgroundImg from "../../assets/images/note-background.png";
import DatePickerWithName from "./DatePickerWithName";
import { StyledFlexColumnView } from "./StyledComponents";

function CreateNote({
    openNoteDialog,
    handleCloseNoteDialog,
    handleCreateNote,
    handleNoteTextChange,
    addNoteLoading,
    id,
    noteList,

}) {
    const option = {
        from_date: new Date(),
        to_date: new Date(),
        onChange: (newData) => {
            console.log('Date changed:', newData);
        }
    };
    const [typeValue, setTypeValue] = useState("");

    useEffect(() => {
        if (openNoteDialog) {
            setTypeValue("");
        }
    }, [openNoteDialog]);


    return (
        <Dialog
            fullWidth
            maxWidth={"xs"}
            open={openNoteDialog}
            onClose={handleCloseNoteDialog}
            sx={{
                "& .MuiDialog-paper": {
                    width: "700px",
                    maxWidth: "none",
                    borderRadius: "12px",
                    background: "#FFF",
                    boxShadow:
                        "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
                },
            }}
        >
            <img
                src={NoteBackgroundImg}
                style={{ position: "absolute", width: "max-content", opacity: "0.7" }}
            />
            <DialogActions>
                <Button onClick={handleCloseNoteDialog} sx={{ marginTop: "10px" }}>
                    <XCloseIco />
                </Button>
            </DialogActions>
            <DialogTitle>
                <Typography variant="h3" sx={{ mt: 2 }}>
                    {"Create Note"}
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Grid2 spacing={2} container>
                    <>
                        <Grid2 item xs={12}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center", width: "48%" }}>
                                    <TextField
                                        fullWidth
                                        value={"Client"}
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{
                                            flex: 0.5,
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "8px 0 0 8px",
                                            },
                                            "& .MuiInputBase-input": {
                                                padding: "12px",
                                                fontWeight: 500,
                                                fontSize: "16px",
                                                color: "#667085",
                                            },
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        value={"Porsche"}
                                        variant="outlined"
                                        sx={{
                                            flex: 1,
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "0 8px 8px 0",
                                            },
                                            "& .MuiInputBase-input": {
                                                padding: "12px",
                                            },
                                        }}
                                    />
                                </div>
                                <div style={{ display: "flex", alignItems: "center", width: "48%" }}>
                                    <TextField
                                        fullWidth
                                        value={"Date"}
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{
                                            flex: 0.5,
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "8px 0 0 8px",
                                            },
                                            "& .MuiInputBase-input": {
                                                padding: "12px",
                                                fontWeight: 500,
                                                fontSize: "16px",
                                                color: "#667085",
                                            },
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        value={"22 Nov 2024"}
                                        variant="outlined"
                                        sx={{
                                            flex: 1,
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "0 8px 8px 0",
                                            },
                                            "& .MuiInputBase-input": {
                                                padding: "12px",
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </Grid2>
                        <Grid2 item xs={12}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center", width: "48%" }}>
                                    <TextField
                                        fullWidth
                                        value={"Position"}
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{
                                            flex: 0.5,
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "8px 0 0 8px",
                                            },
                                            "& .MuiInputBase-input": {
                                                padding: "12px",
                                                fontWeight: 500,
                                                fontSize: "16px",
                                                color: "#667085",
                                            },
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        value={"Product Designer"}
                                        variant="outlined"
                                        sx={{
                                            flex: 1,
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "0 8px 8px 0",
                                            },
                                            "& .MuiInputBase-input": {
                                                padding: "12px",
                                            },
                                        }}
                                    />
                                </div>
                                <div style={{ display: "flex", alignItems: "center", width: "48%" }}>
                                    <TextField
                                        fullWidth
                                        value={"Job ID"}
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{
                                            flex: 0.5,
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "8px 0 0 8px",
                                            },
                                            "& .MuiInputBase-input": {
                                                padding: "12px",
                                                fontWeight: 500,
                                                fontSize: "16px",
                                                color: "#667085",
                                            },
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        value={"123456"}
                                        variant="outlined"
                                        sx={{
                                            flex: 1,
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "0 8px 8px 0",
                                            },
                                            "& .MuiInputBase-input": {
                                                padding: "12px",
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </Grid2>
                        <Grid2 size={12}>
                            <StyledFlexColumnView>
                                <Typography
                                    sx={{ fontWeight: 500, fontSize: "16px", color: "#344054" }}
                                >
                                    {"Type"}
                                </Typography>
                                <Autocomplete
                                    disablePortal
                                    options={["Blank", "Supplier Call"]}
                                    value={typeValue}
                                    onChange={(event, newValue) => setTypeValue(newValue)}
                                    sx={{
                                        "& .MuiAutocomplete-inputRoot": {
                                            padding: "5px",
                                            border: "1px solid #D0D5DD",
                                            alignItems: "center",
                                            borderRadius: "8px",
                                            backgroundColor: "#FFFFFF",
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            color: "#667085",
                                            opacity: 1,
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        },
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth variant="outlined" />
                                    )}
                                    popupIcon={
                                        <IconButton sx={{ padding: 0 }}>
                                            <ChevronDownIco />
                                        </IconButton>
                                    }
                                />
                            </StyledFlexColumnView>
                        </Grid2>
                        {typeValue === "Blank" && (

                            <Grid2 size={12} container spacing={3}>
                                <>
                                    <Grid2 size={12}>
                                        <StyledFlexColumnView>
                                            <Typography
                                                color="#344054"
                                                fontSize={14}
                                                fontFamily={"Inter"}
                                                fontWeight={500}
                                            >
                                                Note
                                            </Typography>

                                            <TextField
                                                id="outlined-multiline-static"
                                                // label="Multiline"
                                                placeholder="placeholder"
                                                multiline
                                                rows={4}
                                                // value={note.note}
                                                onChange={(e) => handleNoteTextChange(e)}
                                                fullWidth
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                    fontFamily: "inter",
                                                }}
                                            />
                                        </StyledFlexColumnView>
                                    </Grid2>

                                    <Grid2 size={12}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            startIcon={<RecordingIco />}
                                            sx={{
                                                width: "100%",
                                                padding: "10px 14px",
                                                borderRadius: "8px",
                                                color: "#344054",
                                                border: "1px solid #D0D5DD",
                                                "&:hover": {
                                                    backgroundColor: "#7F56D9",
                                                },
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {"Record"}
                                        </Button>
                                    </Grid2>


                                </>
                            </Grid2>
                        )}
                        {typeValue === "Supplier Call" && (
                            <>
                                <Grid2 size={6}>
                                    <StyledFlexColumnView>
                                        <Typography
                                            sx={{ fontWeight: 500, fontSize: "16px", color: "#344054" }}
                                        >
                                            {"Deadline"}
                                        </Typography>
                                        <DatePickerWithName
                                            value={"Select"}
                                            startDateProp={option.from_date}
                                            endDateProp={option.to_date}
                                            handleChange={(start, end) => {
                                                option.onChange({
                                                    ...option,
                                                    from_date: start,
                                                    to_date: end
                                                });
                                            }}
                                        />
                                    </StyledFlexColumnView>
                                </Grid2>
                                <Grid2 size={6}>
                                    <StyledFlexColumnView>
                                        <Typography
                                            sx={{ fontWeight: 500, fontSize: "16px", color: "#344054" }}
                                        >
                                            {"Job Description History"}
                                        </Typography>
                                        <Autocomplete
                                            disablePortal
                                            options={["New"]}
                                            defaultValue={["New"]}
                                            sx={{
                                                "& .MuiAutocomplete-inputRoot": {
                                                    padding: "5px",
                                                    border: "1px solid #D0D5DD",
                                                    alignItems: "center",
                                                    borderRadius: "8px",
                                                    backgroundColor: "#FFFFFF",
                                                },
                                                "& .MuiInputBase-input::placeholder": {
                                                    color: "#667085",
                                                    opacity: 1,
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            )}
                                            popupIcon={
                                                <IconButton sx={{ padding: 0 }}>
                                                    <ChevronDownIco />
                                                </IconButton>
                                            }
                                        />
                                    </StyledFlexColumnView>
                                </Grid2>
                                <Grid2 size={12}>
                                    <StyledFlexColumnView>
                                        <Typography
                                            color="#344054"
                                            fontSize={16}
                                            fontFamily={"Inter"}
                                            fontWeight={500}
                                        >
                                            History Reason
                                        </Typography>

                                        <TextField
                                            id="outlined-multiline-static"
                                            placeholder="Enter History Reason"
                                            multiline
                                            rows={4}
                                            onChange={(e) => handleNoteTextChange(e)}
                                            fullWidth
                                            sx={{
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                fontFamily: "inter",
                                            }}
                                        />
                                    </StyledFlexColumnView>
                                </Grid2>
                                <Grid2 size={12}>
                                    <StyledFlexColumnView>
                                        <Typography
                                            sx={{ fontWeight: 500, fontSize: "16px", color: "#344054" }}
                                        >
                                            {"Accepting Less / More Experienced Canidates"}
                                        </Typography>
                                        <Autocomplete
                                            disablePortal
                                            options={["Yes", "No"]}
                                            defaultValue={["Yes"]}
                                            sx={{
                                                "& .MuiAutocomplete-inputRoot": {
                                                    padding: "5px",
                                                    border: "1px solid #D0D5DD",
                                                    alignItems: "center",
                                                    borderRadius: "8px",
                                                    backgroundColor: "#FFFFFF",
                                                },
                                                "& .MuiInputBase-input::placeholder": {
                                                    color: "#667085",
                                                    opacity: 1,
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            )}
                                            popupIcon={
                                                <IconButton sx={{ padding: 0 }}>
                                                    <ChevronDownIco />
                                                </IconButton>
                                            }
                                        />
                                    </StyledFlexColumnView>
                                </Grid2>
                                <Grid2 size={6}>
                                    <StyledFlexColumnView>
                                        <Typography
                                            sx={{ fontWeight: 500, fontSize: "16px", color: "#344054" }}
                                        >
                                            {"Locals Only"}
                                        </Typography>
                                        <Autocomplete
                                            disablePortal
                                            options={["Yes", "No"]}
                                            defaultValue={["No"]}
                                            sx={{
                                                "& .MuiAutocomplete-inputRoot": {
                                                    padding: "5px",
                                                    border: "1px solid #D0D5DD",
                                                    alignItems: "center",
                                                    borderRadius: "8px",
                                                    backgroundColor: "#FFFFFF",
                                                },
                                                "& .MuiInputBase-input::placeholder": {
                                                    color: "#667085",
                                                    opacity: 1,
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            )}
                                            popupIcon={
                                                <IconButton sx={{ padding: 0 }}>
                                                    <ChevronDownIco />
                                                </IconButton>
                                            }
                                        />
                                    </StyledFlexColumnView>
                                </Grid2>
                                <Grid2 size={6}>
                                    <StyledFlexColumnView>
                                        <Typography
                                            sx={{ fontWeight: 500, fontSize: "16px", color: "#344054" }}
                                        >
                                            {"Hybrid Number of Days on Site"}
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Enter Days Number"
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    borderRadius: "8px",
                                                    backgroundColor: "#FFFFFF",
                                                    border: "1px solid #D0D5DD",
                                                },
                                                "& .MuiInputBase-input": {
                                                    padding: "12px",
                                                },
                                                "& .MuiInputBase-input::placeholder": {
                                                    color: "#667085",
                                                    opacity: 1,
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                },
                                            }}
                                        />
                                    </StyledFlexColumnView>
                                </Grid2>
                                <Grid2 size={12}>
                                    <StyledFlexColumnView>
                                        <Typography
                                            color="#344054"
                                            fontSize={16}
                                            fontFamily={"Inter"}
                                            fontWeight={500}
                                        >
                                            Project Scope
                                        </Typography>

                                        <TextField
                                            id="outlined-multiline-static"
                                            placeholder="Enter Project Scope"
                                            multiline
                                            rows={4}
                                            onChange={(e) => handleNoteTextChange(e)}
                                            fullWidth
                                            sx={{
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                fontFamily: "inter",
                                            }}
                                        />
                                    </StyledFlexColumnView>
                                </Grid2>
                                <Grid2 size={12}>
                                    <StyledFlexColumnView>
                                        <Typography
                                            color="#344054"
                                            fontSize={16}
                                            fontFamily={"Inter"}
                                            fontWeight={500}
                                        >
                                            Other Details
                                        </Typography>

                                        <TextField
                                            id="outlined-multiline-static"
                                            placeholder="Enter Other Details"
                                            multiline
                                            rows={4}
                                            onChange={(e) => handleNoteTextChange(e)}
                                            fullWidth
                                            sx={{
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                fontFamily: "inter",
                                            }}
                                        />
                                    </StyledFlexColumnView>
                                </Grid2>
                            </>
                        )}

                        <Grid2 size={12}>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{
                                    width: "100%",
                                    padding: "10px 14px",
                                    borderRadius: "8px",
                                    color: "#344054",
                                    border: "1px solid #D0D5DD",
                                    "&:hover": {
                                        backgroundColor: "#7F56D9",
                                    },
                                    fontWeight: "bold",
                                    "&.Mui-disabled": {
                                        color: "#98A2B3",
                                        fontWeight: "bold",
                                        border: "1px solid #EAECF0",
                                        backgroundColor: "#F2F4F7",
                                    },
                                }}
                                onClick={handleCreateNote}
                                startIcon={
                                    addNoteLoading ? (
                                        <CircularProgress color="inherit" size={15} />
                                    ) : null
                                }
                            >
                                {"Create Note"}
                            </Button>
                        </Grid2>
                    </>

                </Grid2>
            </DialogContent>
        </Dialog >
    );
}

export default CreateNote;
