import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CommonButton from "../../component/Button";
import SnackBar from "../../component/SnackBar";
import { CommonGrid2 } from "../../modules/common-components/StyledComponents";
import DialogCandidateHub from "../../modules/jobs/jobs-candidate/components/DialogCandidateHub";
import { ResumeIntelligenceSave } from "../../redux/action/ResumeIntelligence";
import Sidebar from "./Components/Sidebar";

function PortalLayout() {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [saveCandidateOpen, setSaveCandidateOpen] = useState(false);
  const [storePathName, setStorePathName] = useState("");
  const dispactch = useDispatch();
  const [loading, setLoading] = useState({
    disabled: false,
    leaveLoading: false,
    saveLoading: false,
  });
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!lgUp) setOpen(false);
  }, [lgUp]);

  const handleNavigation = (path) => {
    if (
      location.pathname + location.search ===
      "/reusme-intelligence/create-candidate-resume?resume=parse"
    ) {
      setSaveCandidateOpen(true);
      setStorePathName(path);
    } else if (path) navigate(path);
  };

  const handleSaveCandidate = () => {
    setSaveCandidateOpen(false);
  };

  const resumeIntelligence = useSelector(
    (state) => state.resumeIntelligence.resumeIntelligenceUpload
  );

  const handleLeave = () => {
    setLoading((prev) => ({ ...prev, disabled: true, leaveLoading: true }));
    setTimeout(() => {
      setLoading((prev) => ({ ...prev, disabled: false, leaveLoading: false }));
      setSaveCandidateOpen(false);
      navigate(storePathName);
    }, 1000);
  };

  const handleSave = async () => {
    setLoading((prev) => ({ ...prev, disabled: true, saveLoading: true }));
    try {
      const data = await dispactch(
        ResumeIntelligenceSave(resumeIntelligence.data)
      );
      setLoading((prev) => ({ ...prev, disabled: false, saveLoading: false }));
      setSnackbarState({
        open: true,
        severity: "success",
        message: data.message,
      });
      setSaveCandidateOpen(false);
      navigate(storePathName);
    } catch (error) {
      setLoading((prev) => ({ ...prev, disabled: false, saveLoading: false }));
      setSnackbarState({
        open: true,
        severity: "error",
        message: error?.data?.message || error.message,
      });
    }
  };

  const Footer = () => (
    <CommonGrid2 display={"flex"} gap={"1rem"}>
      <CommonButton
        value={"Leave"}
        variant="outlined"
        color="#344054"
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        border={"#D0D5DD"}
        onClick={handleLeave}
        disabled={loading.disabled}
        loading={loading.leaveLoading}
      />
      <CommonButton
        value={"Save"}
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        disabled={loading.disabled}
        loading={loading.saveLoading}
        onClick={handleSave}
      />
    </CommonGrid2>
  );

  return (
    <Box display={"flex"} minHeight={"100vh"} overflow={"hidden"}>
      <Box
        flexShrink={0}
        width={!lgUp ? theme.spacing(8) : open ? 312 : theme.spacing(8)}
        borderRight={"1px solid #EAECF0"}
        height={"100vh"}
        position={"sticky"}
        top={0}
        sx={{
          transition: theme.transitions.create("width", {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeInOut,
          }),
        }}
      >
        <Box
          padding={theme.spacing(1)}
          position={"absolute"}
          top={"3.2rem"}
          zIndex={"9999"}
          right={"-27px"}
          sx={{
            transition: theme.transitions.create(["right"], {
              duration: theme.transitions.duration.short,
              easing: theme.transitions.easing.easeInOut,
            }),
            "& button": {
              borderRadius: "10px",
              padding: "5px",
            },
          }}
        >
          <IconButton
            onClick={toggleDrawer}
            sx={{
              background: "#7F56D9",
              color: "#fff",
              "&:hover": { background: "#7F56D9" },
            }}
          >
            {open ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Box>
        <Sidebar
          open={open}
          setOpen={setOpen}
          handleNavigation={handleNavigation}
        />
      </Box>
      <Box
        flex={1}
        height={"100vh"}
        padding={"32px"}
        sx={{
          overflowY: "auto",
          transition: theme.transitions.create(["max-width", "margin"], {
            duration: theme.transitions.duration.standard,
          }),
        }}
      >
        <Outlet />
      </Box>
      <DialogCandidateHub
        open={saveCandidateOpen}
        title="Save to Candidate Hub?"
        handleClose={handleSaveCandidate}
        footer={<Footer />}
        content={
          "You have unsaved changes. If you leave this page, your changes will be lost. Are you sure you want to proceed?"
        }
      />
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </Box>
  );
}

export default PortalLayout;
