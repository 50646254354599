import { URL } from "../../api/Api";
import { JOBS } from "../Action";
import { catchError, DELETE, GET, POST, PUT } from "../axios/Axios";

export const JobSummary = (job_id) => {
  return async (dispatch) => {
    try {
      const response = await GET(`${URL}/intake/job-summary/${job_id}`, {});
      dispatch({ type: JOBS.jobSummary, data: response.data });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export const JobJobEdit = (job_id) => {
  return async (dispatch) => {
    try {
      const response = await GET(`${URL}/intake/jobdata/edit/${job_id}`, {});
      dispatch({ type: JOBS.jobJobEdit, data: response.data });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export const JobJobSkillEdit = async (id, key, classification, payload) => {
  try {
    const getClassification = classification
      ? `&classification=${classification}`
      : "";
    const response = await POST(
      `${URL}/intake/skill/update?id=${id}&key=${key}${getClassification}`,
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    console.log(error, "sjfnbsjkfsbfn");
    throw catchError(error);
  }
};

export const JobJobShare = async (payload, type) => {
  try {
    const response = await POST(
      type === "summary"
        ? `${URL}/intake/share/job`
        : `${URL}/matchengine/share/candidateprofile`,
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobJobSubmit = async (id, payload) => {
  try {
    const response = await PUT(`${URL}/intake/jobdata/edit/${id}`, payload, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobJobDelete = async (id) => {
  try {
    const response = await DELETE(
      `${URL}/matchengine/match/resume/jd/${id}`,
      {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const JobJobLock = async (payload) => {
  try {
    const response = await PUT(
      `${URL}/matchengine/matchresume/order`,
      payload,
      {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};
