import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import CandidateHub from "../../../../assets/icons/CandidateHub";
import XCloseIco from "../../../../assets/icons/XCloseIco";

const DialogCandidateHub = ({ open, handleClose, footer, content, title }) => {
  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "10px",
        },
      }}
    >
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <CandidateHub margin={"10px 0px 0px 10px"} />
        <Button onClick={handleClose}>
          <XCloseIco />
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="p" fontSize={"15px"}>
          {content}
        </Typography>

        {footer}
      </DialogContent>
    </Dialog>
  );
};

export default DialogCandidateHub;
