import {
  Box,
  Grid2,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import {
  StyledTableBodyTableCell,
  StyledTableTitleTableCell,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";

function StrengthWeak({ size, matchedData }) {
  const skillsData = {
    strength: [
      { skill: "Communication", rating: 5, percentage: 96 },
      { skill: "Critical Thinking", rating: 4.5, percentage: 88 },
      { skill: "Problem Solving", rating: 4, percentage: 80 },
    ],
    weakness: [
      { skill: "Communication", rating: 2, percentage: 40 },
      { skill: "Critical Thinking", rating: 1, percentage: 10 },
      { skill: "Problem Solving", rating: 1.5, percentage: 28 },
    ],
  };

  const renderSkills = (skills, title) => (
    <TableContainerBorder sx={{ marginTop: "0px !important" }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableTitleTableCell sx={{ width: "50%" }}>
              {title}
            </StyledTableTitleTableCell>
            <StyledTableTitleTableCell sx={{ width: "50%" }} align="center">
              {"Rating"}
            </StyledTableTitleTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {skills?.map((item, index) => (
            <TableRow key={index}>
              <StyledTableBodyTableCell>
                {item?.category}
              </StyledTableBodyTableCell>
              <TableCell align="right">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ gap: "10px", marginRight: "20px !important" }}
                >
                  <Rating
                    value={item?.rating}
                    precision={0.5}
                    readOnly
                    sx={{
                      color: "#FFA500",
                      fontSize: "24px",
                      mr: 1,
                      // width: "150px",
                    }}
                  />
                  <Box sx={{ width: "40px" }}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "#101828",
                      }}
                    >
                      {item?.score.toFixed(2)}%
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainerBorder>
    // <Box
    //   sx={{
    //     border: "1px solid #e0e0e0",
    //     borderRadius: 2,
    //     backgroundColor: "#f9fafb",
    //     width: "100%",
    //   }}
    // >
    //   {/* Header */}
    //   <Box
    //     sx={{
    //       display: "flex",
    //       justifyContent: "space-between",
    //       p: 2,
    //       borderBottom: "1px solid #e0e0e0",
    //     }}
    //   >
    //     <Typography variant="subtitle1" fontWeight="bold" color="textSecondary">
    //       {title}
    //     </Typography>
    //     <Typography variant="subtitle1" fontWeight="bold" color="textSecondary">
    //       Rating
    //     </Typography>
    //   </Box>

    //   {/* Skills List */}
    //   {skills.map(({ skill, rating, percentage }, index) => (
    //     <Box
    //       key={index}
    //       display="flex"
    //       justifyContent="space-between"
    //       alignItems="center"
    //       sx={{
    //         px: 2,
    //         py: 1.5,
    //         borderBottom:
    //           index < skills.length - 1 ? "1px solid #e0e0e0" : "none",
    //       }}
    //     >
    //       {/* Skill Name */}
    //       <Typography variant="body2" color="textPrimary">
    //         {skill}
    //       </Typography>

    //       {/* Rating and Percentage */}
    //       <Box display="flex" alignItems="center">
    //         <Rating
    //           value={rating}
    //           precision={0.5}
    //           readOnly
    //           sx={{ color: "#FFA500", fontSize: "1.2rem", mr: 1 }}
    //         />
    //         <Typography
    //           variant="body2"
    //           color="textSecondary"
    //           sx={{ fontWeight: "bold" }}
    //         >
    //           {percentage}%
    //         </Typography>
    //       </Box>
    //     </Box>
    //   ))}
    // </Box>
  );
  return (
    <Grid2 container spacing={4} justifyContent="center">
      <Grid2 size={size}>
        {renderSkills(matchedData?.insights?.strengths, "Strength")}
      </Grid2>
      <Grid2 size={size}>
        {renderSkills(matchedData?.insights?.weaknesses, "Weakness")}
      </Grid2>
    </Grid2>
  );
}

export default StrengthWeak;
